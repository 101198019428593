import * as Updates from 'expo-updates'
import Constants from 'expo-constants'

let config = {
  version: Constants.expoConfig.version,
  appEnv: 'local',
  api: { baseUrl: 'https://cwscorapp.com/api', timeout: 30000 },
}

console.log(Constants.expoConfig)

if (Constants.expoConfig.extra.APP_ENV === 'production') {
  config.appEnv = 'production'
  config.api.baseUrl = 'https://cwscorapp.com/api'
} else if (Constants.expoConfig.extra.APP_ENV === 'development') {
  config.appEnv = 'development'
  config.api.baseUrl = 'https://cwscorapp.com/api'
}

export default config
